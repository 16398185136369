import { useEffect, useState } from 'react'

import { getBaseUrl } from '@/lib/contentful/utils'

type Props = {
	locale?: string
	path?: string
}
export function useUrl({ locale, path }: Props = {}) {
	const [currentUrl, setCurrentUrl] = useState<string | null>(null)

	useEffect(() => {
		setCurrentUrl(window.location.href)
	}, [path])

	if (typeof window === 'undefined' && locale) {
		const localeBaseUrl = getBaseUrl(locale)

		return localeBaseUrl && path ? new URL(path, localeBaseUrl) : new URL(localeBaseUrl)
	}
	return currentUrl ? new URL(currentUrl) : null
}
